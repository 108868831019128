
import { defineComponent, Ref, ref, onMounted, onBeforeMount, watch } from "vue";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRoute, useRouter } from "vue-router";
import Pagination from "@/components/Pagination.vue";
import { useLoaderStore } from "@/store/LoaderStore";

interface AttendanceFilter {
  placa: string;
  veiculo: string;
  responsavel: string;
  data: any;
  status: string;
  idAtendimento?: string;
  codConcessionaria: any;
}

export default defineComponent({
  name: "Attendance",

  components: {
    Pagination,
    Title,
    Button,
  },

  setup() {
    const emitter = useEmitter();
    const none: Ref<boolean> = ref(false)
    const router = useRouter();
    const route = useRoute();
    const getUrlParam = (param: string) => new URLSearchParams(window.location.search).get(param);

    // input 'Tue Jan 03 2023 00:00:00 GMT-0300 (Brasilia Standard Time)'
    // output '2023-01-03 03:00:00'
    const specificFormatData = (date: any) => new Date(date).toISOString().substr(0, 19).replace("T", " ");

    const maxLimitByPage = 10;

    // const loadAttendances: Ref<boolean> = ref(false);
    const storeSelected: Ref<any> = ref("");

    const storeId: Ref<number | string> = ref(getUrlParam("storeId") ? Number(getUrlParam("storeId")) : '');
    const nomeConcessionaria: Ref<string> = ref('')

    const attendanceArray: Ref<any[]> = ref([]);
    const total: Ref<number> = ref(0);
    const page: Ref<number | null> = ref(null);
    const lojas: Ref<any> = ref([]);
    const statusAttendance: string[] = ["Aberto", "Enviado", "Fechado", "Vendido", "Cancelado", "Andamento", "Precificar", "Pendente Aprovação"];
    const concessionariasArray: Ref<any> = ref([]) 
    const loadingAttendance = useLoaderStore();
    const atendimentoPaginationRef = ref<InstanceType<typeof Pagination> | null>(null);

    const attendanceFilter: Ref<AttendanceFilter> = ref({
      placa: "",
      veiculo: "",
      responsavel: "",
      data: "",
      status: "",
      idAtendimento:'',
      codConcessionaria: [],
    });

    const fastFind: Ref<string> = ref('');
    const lojaFixada: Ref<any> = ref(null)

    function hasValues(obj: any) {
      for (let key in obj) {
        if (obj[key] !== "" && (!Array.isArray(obj[key]) || obj[key].length > 0)) {
          return true;
        }
      }
      return false;
    }

    function handlePageChange(pageParam) {
      page.value = pageParam;
      getAttendances(pageParam, 10, true);
    }

    const handlePage = async (paramPage: number) => await getAttendances(paramPage, maxLimitByPage);

    async function getAttendances(pageParam: number, limit: number, search = false) {
      try{
        loadingAttendance.open();
        let attendanceFilter2;
        if(concessionariasArray.value){ 
          attendanceFilter2 = {...attendanceFilter.value, codConcessionaria: [...concessionariasArray.value]}
        }
  
        // loadAttendances.value = true;
        attendanceFilter.value.codConcessionaria = [storeId.value && storeId.value as any];
  
        if (attendanceFilter.value.data) {
          attendanceFilter.value.data[0] = specificFormatData(attendanceFilter.value.data[0]);
          attendanceFilter.value.data[1] = specificFormatData(attendanceFilter.value.data[1]);
        }
        
        const { data } = await Api.get("getAttendancesByPage", { page: pageParam, limit, ...(concessionariasArray.value.length ? { ...attendanceFilter2 }  : { ...attendanceFilter.value })});
  
        total.value = data.total;
        page.value = pageParam;
        
        if (!search) attendanceArray.value = [...attendanceArray.value, ...data.atendimentos];
        else attendanceArray.value = data.atendimentos;
  
        if(storeId.value) {
          attendanceArray.value.length ? nomeConcessionaria.value = attendanceArray.value[0].nomeFantasia : ''
        }
  
        // concessionariasArray.value = []
        // loadAttendances.value = false;
        loadingAttendance.close();

      }catch(error) {
        loadingAttendance.close();
        console.log(error)
      }

    }

    function redirectToAttendanceDetails(id: number): void {
      router.push({
        path: `/lojas/atendimentos/detalhes`,
        query: { id: id.toString() }
      });
    }

    async function getLojas() {
      const res = await Api.get("getAllStores");
      lojas.value = res.data.lojas;
      if (storeId.value) {
        const store = lojas.value.find((idLoja: any) => idLoja.codConcessionaria == storeId.value);
        storeSelected.value = store ? store : null;
        handlePage(1);
      }
    }

    emitter.on("Filter-Atendimentos", (props:any) => {
      attendanceFilter.value = {
        placa: props.placa,
        veiculo: props.veiculo,
        responsavel: props.responsavel,
        data: props.data,
        status: props.status,
        idAtendimento: props.idAtendimento,
        codConcessionaria: props.codConcessionariaTeste,
      }

      concessionariasArray.value = props.codConcessionariaTeste
      getAttendances(1, maxLimitByPage, true)
      if (atendimentoPaginationRef.value) {
        atendimentoPaginationRef.value.resetPage(1);
      } 
    });

    function getLocalStorage(){
      lojaFixada.value = JSON.parse(localStorage.getItem('lojaFixada') as any)      
      if(lojaFixada.value){
        
        attendanceFilter.value.codConcessionaria = lojaFixada.value && lojaFixada.value.id
        storeId.value = lojaFixada.value.id
      }
    }

    async function resetObject(obj) {
      for (const prop in obj) {
        obj[prop] = typeof obj[prop] === "object" ? {} : "";
      }
    }

    watch(
      () => fastFind.value,
      async (newVal) => {
        resetObject(attendanceFilter.value);
        if (newVal && newVal.length > 2) {
          attendanceFilter.value.placa = newVal;
          await getAttendances(1, maxLimitByPage, true);
        }
        if (!attendanceArray.value.length && newVal) {
          resetObject(attendanceFilter.value);
          attendanceFilter.value.veiculo = newVal;
          await getAttendances(1, maxLimitByPage, true);
        }
        if (!attendanceArray.value.length && newVal) {
          resetObject(attendanceFilter.value);
          attendanceFilter.value.responsavel = newVal;
          await getAttendances(1, maxLimitByPage, true);
        }
        if (!attendanceArray.value.length && newVal) {
          resetObject(attendanceFilter.value);
          attendanceFilter.value.idAtendimento = newVal;
          await getAttendances(1, maxLimitByPage, true);
        }
      if(!fastFind.value) await getAttendances(1, maxLimitByPage, true);
      if (atendimentoPaginationRef.value) {
        atendimentoPaginationRef.value.resetPage(1);
      } 
      }
    );

    watch(
      () => route.query.storeId,
      () =>{
        attendanceFilter.value.codConcessionaria = [],
        handlePage(1);
      }
    );


    onBeforeMount(async () => {      
      getLocalStorage();
      handlePage(1);
      getLojas();
      
    });

    return {
      page,
      maxLimitByPage,
      total,
      attendanceArray,
      handlePage,
      getAttendances,
      redirectToAttendanceDetails,
      attendanceFilter,
      history,
      console,
      getLojas,
      lojas,
      statusAttendance,
      storeSelected,
      hasValues,
      none,
      lojaFixada,
      fastFind,
      nomeConcessionaria,
      storeId,
      route,
      handlePageChange,
      atendimentoPaginationRef
    };
  },
});
